<template>
  <layout-default>
    <v-layout wrap align-center justify-center>
      <v-flex xs12 md6>
        <form>
          <v-card style="max-width: 800px">
            <v-card-title
              class="headline font-weight-regular blue-grey white--text"
              >Create service
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="name"
                label="Name"
                required
                v-validate="'required'"
                :error-messages="errors.collect('name')"
                data-vv-name="name"
              ></v-text-field>
              <v-text-field
                v-model="dockerHubRepoName"
                label="Docker hub repo name"
                required
                v-validate="'required'"
                :error-messages="errors.collect('dockerHubRepoName')"
                data-vv-name="dockerHubRepoName"
                data-vv-as="Docker hub repo name"
              ></v-text-field>
              <v-text-field
                v-model="helmName"
                label="Helm name"
                required
                v-validate="'required'"
                :error-messages="errors.collect('helmName')"
                data-vv-name="helmName"
                data-vv-as="Helm name"
              ></v-text-field>
              <v-text-field
                v-model="bitbucketRepoWorkspace"
                label="Bitbucket repo workspace"
                required
                v-validate="'required'"
                :error-messages="errors.collect('bitbucketRepoWorkspace')"
                data-vv-name="bitbucketRepoWorkspace"
                data-vv-as="Bitbucket repo workspace"
              ></v-text-field>
               <v-text-field
                v-model="bitbucketRepoSlug"
                label="Bitbucket repo slug"
                required
                v-validate="'required'"
                :error-messages="errors.collect('bitbucketRepoSlug')"
                data-vv-name="bitbucketRepoSlug"
                data-vv-as="Bitbucket repo slug"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="info" :loading="createLoading" @click="create">
                Create
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
        <v-alert v-if="lastError" :value="true" color="error" icon="fa-error">{{
          lastError
        }}</v-alert>
      </v-flex>
    </v-layout>
  </layout-default>
</template>

<script>
import LayoutDefault from "@/layouts/Default.vue";
import LIST_SERVICES from "@/graphql/query/ServicesList.gql"
import CREATE_SERVICE from "@/graphql/mutation/CreateServiceMutation.gql";
import { logError } from "@/helpers/debug.ts";

export default {
  $_veeValidate: {
    validator: "new",
  },
  name: "addService",
  components: {
    LayoutDefault,
  },
  data: () => ({
    createLoading: false,
    name: null,
    dockerHubRepoName: null,
    helmName: null,
    bitbucketRepoWorkspace: null,
    bitbucketRepoSlug: null,

    loading: false,
    error: String,
    lastError: null
  }),
  methods: {
    async create() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }

      this.createLoading = true;
      this.$apollo
        .mutate({
          mutation: CREATE_SERVICE,
          variables: {
            name: this.name,
            dockerHubRepoName: this.dockerHubRepoName,
            helmName: this.helmName,
            bitbucketRepoWorkspace: this.bitbucketRepoWorkspace,
            bitbucketRepoSlug: this.bitbucketRepoSlug,
          },
          update: (store, { data: { service } }) => {
            this.createLoading = false;
            let services = store.readQuery({ query: LIST_SERVICES });
            services.services.push(service);
            store.writeQuery({ query: LIST_SERVICES, data: services });

            this.$router.push({ name: "servicesList" });
            return service;
          },
        })
        .catch((error) => {
          this.createLoading = false;
          this.lastError = error;
        });
    }
  },
  apollo: {
    services: {
      query: LIST_SERVICES,
      loadingKey: "loading",
      error(error) {
        logError(error);
        this.error = error;
      }
    },
  },
};
</script>
